/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxiosForClient } from '@/lib/axios';
import type { FormattedContentStackDefaultPDP } from '@/features/contentstack/interface';
import { getContentStackDefaultPDPContent } from '@/features/contentstack/lib/productDetailsPage/getDefaultContent'; // eslint-disable-line no-restricted-imports
import { PageContextType } from '@/next-types';
import {
  QueryClient,
  useQuery,
  QueryFunctionContext,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useSegments } from '@/features/header/api/getSegments';

const URL = `/api/contentstack/productDetailsPages/default`;

type Options = {
  taxonomyPath: string;
};

const getContentStackDefaultPdpContent = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getDefaultPDPKey>>) => {
  const [, , , { segments, taxonomyPath }] = queryKey;
  const response = await getAxiosForClient().get<FormattedContentStackDefaultPDP>(URL, {
    params: {
      segments: segments.join(','),
      taxonomyPath,
    },
  });

  return selector(response.data);
};

const selector = (data: FormattedContentStackDefaultPDP) => {
  return data;
};

export const getDefaultPDPKey = (taxonomyPath: string, segments: string[]) =>
  ['cms', 'default', 'pdp', { segments, taxonomyPath }] as const;

export const useContentStackDefaultPDPContent = (
  options: Omit<
    UseQueryOptions<
      FormattedContentStackDefaultPDP,
      unknown,
      FormattedContentStackDefaultPDP,
      ReturnType<typeof getDefaultPDPKey>
    >,
    'queryKey' | 'queryFn'
  > &
    Options
) => {
  const { data: segments, isSuccess } = useSegments();
  return useQuery({
    queryFn: getContentStackDefaultPdpContent,
    queryKey: getDefaultPDPKey(options.taxonomyPath, segments?.sort() ?? []),
    enabled: options.enabled && !!options.taxonomyPath && isSuccess,
  });
};

export const prefetchContentStackDefaultPDPContent = (
  queryClient: QueryClient,
  taxonomyPath: string,
  context: PageContextType,
  userSegments: string[]
) => {
  return queryClient.prefetchQuery({
    queryKey: getDefaultPDPKey(taxonomyPath, userSegments),
    queryFn: () =>
      getContentStackDefaultPDPContent(
        context.req,
        {
          ...context.query,
          taxonomyPath,
        },
        userSegments
      ),
  });
};

export const getContentStackDefaultPDPContentFromCache = (
  queryClient: QueryClient,
  taxonomyPath: string,
  userSegments: string[]
) => {
  return queryClient.getQueryData<FormattedContentStackDefaultPDP>(
    getDefaultPDPKey(taxonomyPath, userSegments)
  );
};
