/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { ContentStackPrefooter } from '@/features/contentstack/interface';
import { PreFooterMiddleItem } from '../interface';

const buildPreFooterMiddleData = (
  cmsPrefooter: NonNullable<ContentStackPrefooter['column']>[0]
): PreFooterMiddleItem => {
  return {
    name: cmsPrefooter.column_title ?? '',
    contents: [
      {
        numItemsDisplay: '10',
        tablet: true,
        desktop: true,
        mobile: true,
        name: cmsPrefooter.column_title ?? '',
        title: cmsPrefooter.column_title ?? '',
        content: (cmsPrefooter.link ?? []).map((link) => ({
          tablet: true,
          desktop: true,
          mobile: true,
          CTALink: link.href,
          name: link.title,
        })),
      },
    ],
  };
};

export { buildPreFooterMiddleData };
