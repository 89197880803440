/**
 * Copyright 2020-2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useRef, useEffect, useState } from 'react';
import { SmartLink as Link } from '@/utils/smartLink';

type Props = {
  CTALink: string;
  name: string;
  className?: string;
  isLinkToFocus?: boolean;
  isAutoFocus?: boolean;
  handleOnAutoFocusedKeyPress?: React.KeyboardEventHandler<HTMLDivElement>;
};

function PreFooterLink({
  CTALink,
  name,
  className,
  isLinkToFocus,
  isAutoFocus,
  handleOnAutoFocusedKeyPress,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const [shouldFocusTop, setShouldFocusTop] = useState(false);
  useEffect(() => {
    if (shouldFocusTop) {
      const azLogoFixed = document.getElementById('azLogoLinkFixed');
      azLogoFixed?.focus();
      setShouldFocusTop(false);
    }
  }, [shouldFocusTop]);

  useEffect(() => {
    if (ref && isLinkToFocus && isAutoFocus) {
      ref.current?.focus();
    }
  }, [ref, isLinkToFocus, isAutoFocus]);

  return (
    <Link
      to={CTALink}
      className={className}
      onClick={() => {
        setShouldFocusTop(true);
      }}
      tabIndex={isLinkToFocus ? -1 : 0}
    >
      {isLinkToFocus ? (
        <span tabIndex={0} ref={ref} onKeyPress={handleOnAutoFocusedKeyPress}>
          {name}
        </span>
      ) : (
        <span tabIndex={-1}>{name}</span>
      )}
    </Link>
  );
}

export { PreFooterLink };
