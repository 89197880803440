/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useEffect, useState } from 'react';
import type { PreFooterLinkColumnProps, PreFooterColumnContent } from '../../interface';
import { PreFooterLink } from './PreFooterLink';
import { useRouter } from 'next/router';
import styles from './styles.module.scss';
import { Label } from '@/features/i18n';
import { useLabel } from '@/hooks/useLabels';
import { Button } from '@/components/Button';
import { eventConstants } from '@/constants/event';

function DesktopLinkColumn({ title, middleLabelContent }: PreFooterLinkColumnProps) {
  const router = useRouter();
  const contentLength = middleLabelContent.length;

  // Show all by default so that bots and non-JS users can see the content
  const [showingAll, setShowingAll] = useState(true);
  const [isAutoFocus, setIsAutoFocus] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(contentLength);

  const minToShow = 5;
  const maxToShow = 10;

  const handleShowHide = (): void => {
    setShowingAll(!showingAll);
    setIsAutoFocus(!isAutoFocus);
  };

  useEffect(() => {
    // Hide unnecessary links for JS users
    setShowingAll(false);
  }, []);

  useEffect(() => {
    const lesser = showingAll
      ? contentLength < maxToShow
        ? contentLength
        : maxToShow
      : contentLength < minToShow
      ? contentLength
      : minToShow;
    setItemsToShow(lesser);
  }, [contentLength, showingAll]);

  const handleOnAutoFocusedKeyPress = (
    e: React.KeyboardEvent<HTMLDivElement>,
    content: PreFooterColumnContent
  ) => {
    const { CTALink } = content;
    if (e.key === eventConstants.enterKeyType) {
      void router.push(CTALink);
    }
  };

  const showLess = useLabel('label_Show_Less');
  const showLessAriaLabel = `${showLess} ${title}`;
  const showAll = useLabel('label_Show_All');
  const showAllAriaLabel = `${showAll} ${title}`;

  return (
    <div className={styles.linkColumnContainer}>
      <h2 className={styles.linkColumnTitle}>{title}</h2>
      {middleLabelContent && renderColumnLinkList()}
      {middleLabelContent.length > minToShow && (
        <Button
          data-testid="show-button"
          onClick={handleShowHide}
          variant="ghostPureText"
          customClass={styles.allOrLess}
          ariaLabel={showingAll ? showLessAriaLabel : showAllAriaLabel}
          ariaExpanded={showingAll ? true : false}
        >
          <Label label={showingAll ? 'label_Show_Less' : 'label_Show_All'} />
        </Button>
      )}
    </div>
  );

  function renderColumnLinkList() {
    const listItems = middleLabelContent.slice(0, itemsToShow);
    return (
      <ul>
        {listItems.map((content: PreFooterColumnContent, index) => {
          return (
            <li className={styles.linkContainer} key={`link_${index}`}>
              <PreFooterLink
                isLinkToFocus={index === minToShow}
                className={styles.linkItem}
                isAutoFocus={isAutoFocus}
                handleOnAutoFocusedKeyPress={(e) => handleOnAutoFocusedKeyPress(e, content)}
                {...content}
              />
            </li>
          );
        })}
      </ul>
    );
  }
}

export { DesktopLinkColumn };
