/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { PageTypeData } from '@/types';
import { type ProductData } from '@/features/product';

type GetPageDependentPrefooterParamsArgs = {
  isPDP: boolean;
  pageTypeData: PageTypeData | undefined;
  productDetails: ProductData | undefined;
};

type PageDependentParams = {
  pageType: string | undefined;
  catalogRepositoryId: string | undefined;
  shopByMakeActive: boolean | undefined;
  shopByModelActive: boolean | undefined;
  shopByYearActive: boolean | undefined;
  taxonomyPath: string | undefined;
  filterName: string | undefined;
  vehicleMake: string | undefined;
  vehicleModel: string | undefined;
  vehicleYear: string | undefined;
};

const getPageDependentPrefooterParams = ({
  isPDP,
  pageTypeData,
  productDetails,
}: GetPageDependentPrefooterParamsArgs): PageDependentParams => {
  if (isPDP) {
    return {
      pageType: 'ProductDetails',
      catalogRepositoryId: productDetails?.product?.ecommProductId,
      shopByMakeActive: productDetails?.product?.shopByMakeActive,
      shopByModelActive: productDetails?.product?.shopByModelActive,
      shopByYearActive: productDetails?.product?.shopByYearActive,
      taxonomyPath: productDetails?.product?.taxonomyUrl,
      filterName: productDetails?.product?.partTypeName,
      vehicleMake: undefined,
      vehicleModel: undefined,
      vehicleYear: undefined,
    };
  }

  return {
    pageType: pageTypeData?.pageType,
    catalogRepositoryId: pageTypeData?.catalogRepositoryId,
    shopByMakeActive: pageTypeData?.shopByMakeActive,
    shopByModelActive: pageTypeData?.shopByModelActive,
    shopByYearActive: pageTypeData?.shopByYearActive,
    taxonomyPath: pageTypeData?.taxonomyPath,
    filterName: pageTypeData?.catalogRepositoryName,
    vehicleMake: pageTypeData?.make ?? undefined,
    vehicleModel: pageTypeData?.model ?? undefined,
    vehicleYear: pageTypeData?.year ?? undefined,
  };
};

export { getPageDependentPrefooterParams };
