/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { ContentStackPrefooter } from '@/features/contentstack/interface';
import type { PreFooterMiddleItem } from '../interface';
import { buildPreFooterMiddleData } from './buildPreFooterDataFromCMSData';

const mergePrefooterBackendWithCMSData = (
  preFooterBackend: PreFooterMiddleItem[],
  preFooterCMSColumns: ContentStackPrefooter['column'] = [],
  isCategoryPage = false
) => {
  const prefooterColumnNames = preFooterBackend.map(
    (prefooterColumn) => prefooterColumn.contents?.[0].title ?? ''
  );

  return preFooterCMSColumns.reduce<PreFooterMiddleItem[]>((columnsToDisplay, cmsColumn, index) => {
    if (isCategoryPage && index === 0) {
      return [...columnsToDisplay, buildPreFooterMiddleData(cmsColumn)];
    }
    if (prefooterColumnNames.includes(cmsColumn.column_title ?? '')) {
      const backendColumn = preFooterBackend.find(
        (columnFromBackend) => columnFromBackend.contents?.[0].title === cmsColumn.column_title
      );

      if (backendColumn?.contents?.[0].content?.length) {
        columnsToDisplay.push(backendColumn);

        return columnsToDisplay;
      }

      columnsToDisplay.push(buildPreFooterMiddleData(cmsColumn));

      return columnsToDisplay;
    }

    return columnsToDisplay;
  }, []);
};

export { mergePrefooterBackendWithCMSData };
